// Here you can add other styles
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #181924;
}

::-webkit-scrollbar-thumb {
  background: #666666;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #969696;
  border-radius: 10px;
}
